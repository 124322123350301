import smoothscroll from "smoothscroll-polyfill";
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import 'whatwg-fetch';

import reduxWrapper from "./src/state/reduxWrapper";

export const wrapPageElement = ({ props, element }) => {
  return reduxWrapper(props, element)
}

// other polyfills
smoothscroll.polyfill();

// // framer hacks
// let listenerRef = null, menuToggle, menuToggleListenerAttached, lol = null;
// const cb = (event) => {
//   if (event.data.source === 'ghub-press') { 
//     if (event.data.payload.framerRendered) {
//       // if (!window.ghub) { window.ghub = {} }
//       window.ghub.framerRendered = true
//       var iframe = document.getElementById('press-source');
//       if (iframe) {
//         console.log(iframe)
//       }
//   }
// }
// if (!listenerRef) {
//   listenerRef = window.addEventListener("message", cb, false); 
// }

// polyfills for Jane
if (!window.ResizeObserver) {
  window.ResizeObserver = require("resize-observer-polyfill");
}

if (!window.IntersectionObserver) {
  window.IntersectionObserver = require("intersection-observer-polyfill");
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const segs = location.pathname.split('/');

  const savedPosition = getSavedScrollPosition(location);
  window.setTimeout(
    () => window.scrollTo(...(savedPosition || [0, 0])),
    100
  );
  
  return false;
};