import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";
// import { useQuery, useSubscription } from "@redux-requests/react";

// import {
//   fetchPage,
//   onPageFetched,
// } from "../../../../common/providers/contentstack/content-types/page/actions";
// import {
//   FETCH_PAGE,
//   ON_PAGE_FETCHED,
// } from "../../../../common/providers/contentstack/content-types/page/constants";

import { useDataDogRum } from "../effects/useDataDogRum";

import { getReduxStore } from "../state/configureStore";
import { getBoundActions } from "../state/actions";
import {
  transformLayoutData,
  transformSiteConfigData,
  transformBuildInfoData,
  transformDatabagData,
} from "../../../../common/providers/contentstack/modular-blocks";

import Layout from "./Layout";
import AppScripts from "./AppScripts";

const App = (props) => {
  const store = getReduxStore("init", props.location.pathname).store;
  const actions = getBoundActions(store.dispatch);

  const [isAppMounted, setIsAppMounted] = useState(false);
  const [isHydrateMounted, setIsHydrateMounted] = useState(false);
  const [hydrateData, setHydrateData] = useState(null);
  const [currentPath, setCurrentPath] = useState(null);

  const cookies =
    typeof document === "object" ? new Cookies(document.cookie) : {};

  // useQuery({
  //   type: FETCH_PAGE,
  //   action: fetchPage,
  //   autoLoad: true,
  //   variables: [props.location.pathname],
  // });

  // useSubscription({
  //   type: ON_PAGE_FETCHED,
  //   action: onPageFetched,
  // });

  useEffect(() => {
    if (!isAppMounted) {
      setIsAppMounted(true);

      console.log("Mounting App.js", props.location.pathname);
    }
  }, [isAppMounted]);

  useEffect(() => {
    if (props.location.pathname !== currentPath) {
      setCurrentPath(props.location.pathname);
      setHydrateData(props.pageResources.json.pageContext);
      setIsHydrateMounted(true);

      return () => {
        setHydrateData(null);
        setIsHydrateMounted(false);
      };
    }
  }, [currentPath, props.location.pathname]);

  useEffect(() => {
    if (isHydrateMounted) {
      if (hydrateData) {
        const cookies =
          typeof document === "object" ? new Cookies(document.cookie) : {};

        actions.hydrate.doSetLayoutData(
          transformLayoutData(hydrateData.content)
        );
        actions.hydrate.doSetSiteConfigData(
          transformSiteConfigData(hydrateData.site_config)
        );
        actions.hydrate.doSetBuildInfoData(
          transformBuildInfoData(hydrateData.build_info)
        );
        actions.hydrate.doSetDatabagData(
          transformDatabagData(hydrateData.databag)
        );
      }
    }
  }, [isHydrateMounted, hydrateData, actions.hydrate]);

  // useDataDogRum(isAppMounted);

  return (
    <>
      <Layout>{props.children}</Layout>
      <AppScripts />
    </>
  );
};

App.propTypes = {
  children: PropTypes.node.isRequired,
};

App.displayName = "App";

export default App;
