import { useEffect } from "react";

export const useScript = ({
  id,
  url,
  type,
  link,
  setAsync,
  setDefer,
  innerHTML,
  charset
}) => {
  useEffect(() => {
    if (!id) {
      console.error('Please provide an ID for this tag.');
      return false;
    }
    const script =
      url || type === "script"
        ? document.createElement("script")
        : document.createElement("link");

    script.id = id;

    if (url) script.src = url;
    if (setAsync) script.async = setAsync;
    if (setDefer) script.defer = setDefer;
    if (innerHTML) script.innerHTML = innerHTML;
    if (link) {
      script.rel = "stylesheet";
      script.href = link;
    }
    if (charset) script.charset = charset;

    console.log('Adding script', script)
    document.querySelector("head").appendChild(script);

  }, [id, url, type, link, setAsync, setDefer, innerHTML, charset]);
};