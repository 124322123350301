import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { decode } from "html-entities";
import { SkipNavLink, SkipNavContent } from "@reach/skip-nav";
import { noop as _noop } from "lodash";
import { usePostHog } from "posthog-js/react";

import "@reach/skip-nav/styles.css";

import { Helmet } from "react-helmet";

import "../styles/layout.css";
// import ProximaNovaUrl from "../fonts/proxima_ssv/ProximaNova-Regular.otf";

const Layout = React.memo((props) => {
  const state = useSelector((state) => state);

  const pageContext = props.children?.props.pageContext;
  const posthog = usePostHog();

  // this needs to not be here
  // const containerStyle = { marginTop: "88px", minHeight: "704px" };

  const bannerData =
    state.hydrate.siteConfigData?.blocks &&
    state.hydrate.siteConfigData?.blocks["interstitial--news-banner"]
      ? state.hydrate.siteConfigData?.blocks["interstitial--news-banner"]
          .component_data
      : null;

  // no site config here means we're in Gatsby dev mode error
  if (!pageContext || !pageContext.site_config) {
    return (
      <>
        <SkipNavLink />

        <Helmet htmlAttributes={{ lang: "en" }} encodeSpecialCharacters={false}>
          <meta charSet="utf-8" />
          <meta name="MobileOptimized" content="width" />
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="viewport" content="width=device-width,initial-scale=1" />
        </Helmet>

        <SkipNavContent />

        <div /*style={containerStyle}*/>{props.children}</div>
      </>
    );
  }

  const title =
    pageContext.content &&
    pageContext.content.page_meta &&
    pageContext.content.page_meta.meta_title &&
    pageContext.content.page_meta.meta_title.length > 0
      ? decode(pageContext.content.page_meta.meta_title)
      : decode(pageContext.title);

  const desc =
    pageContext.content &&
    pageContext.content.page_meta &&
    pageContext.content.page_meta.meta_description &&
    pageContext.content.page_meta.meta_description.length > 0
      ? decode(pageContext.content.page_meta.meta_description)
      : decode(pageContext.site_config.seo.meta_description);

  const metaTitle = pageContext.site_config
    ? "Home" + " | " + decode(pageContext.site_config.site_title)
    : "";

  const metaDesc = pageContext.site_config ? desc : null;

  const metaRobots = pageContext.site_config
    ? decode(pageContext.site_config.seo.meta_robots)
    : null;

  // const image = "https://images.contentstack.io/v3/assets/bltc2a866f5b4786ae7/blt25caeed96a5bc714/62cc55c6181754349ea306ce/cc_card.png";

  return (
    <>
      <Helmet
        htmlAttributes={{ lang: "en" }}
        encodeSpecialCharacters={false}
        title={`${metaTitle}`}
      >
        <meta charSet="utf-8" />
        <meta name="MobileOptimized" content="width" />
        <meta name="HandheldFriendly" content="true" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta name="description" content={`${metaDesc}`} />
        <meta name="robots" content={`${metaRobots}`} />

        {/* <meta property="og:title" content={ `${metaTitle}` } />
        <meta property="og:description" content={ `${ metaDesc }` } />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={ image } />
        <meta property="og:image:width" content="800" />
        <meta property="og:image:height" content="418" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={ `${metaTitle}` } />
        <meta name="twitter:site" content="@ColumbiaCare" />
        <meta name="twitter:image" content={ image } />
        <meta name="twitter:image:alt" content="Columbia Care" />
        <meta name="twitter:description" content={ `${ metaDesc }` } /> */}
      </Helmet>

      <div /*style={containerStyle}*/>
        <SkipNavContent />
        {/* {props.children} */}
        {React.Children.map(props.children, (child) =>
          React.cloneElement(child, { posthog })
        )}
      </div>
    </>
  );
});

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

Layout.displayName = "Layout";

export default Layout;
